import React, { useReducer } from 'react';
import ProjectManagementContext from './pm-context';

const defaultProjectState = {
  projectCoords: {},
  projectZoom: 0,
  projectName: '',
  routeCoords: '',
  routeDistance: 0,
  selectedTypes: '',
  selectedCategories: '',
  addVector: false,
  vectorSource: '',
  vectorDestination: '',
  sensorAdded: false,
  sensorDeleted: false,
  sensorsDetail: null,
  radarAdded: false,
  radarDeleted: false,
  cameraAdded: false,
  cameraDeleted: false,
  vectorAdded: false,
  vectorDeleted: false,
  newDeviceLocation: '',
  sensorsBounds: null,
  radarsBounds: null,
  camerasBounds: null,
  vectorsBounds: null,
  alarmBounds: null,
  gpsBounds: null,
  gpsLayer: null,
  gpsAdded: null,
  envstationDeleted: false,
  envstationAdded: false,
  envstationsBounds: null,
  envstationsLayer: null,
  daiBounds: null,
  vmsDeleted: false,
  vmsAdded: false,
  vmsBounds: null,
  vmsLayer: null,
  chargerDeleted: false,
  chargerAdded: false,
  chargersBounds: null,
  chargerLayer: null,
  moveMap: true,
  btSensorsLayer: null,
  radarsLayer: null,
  camerasLayer: null,
  newVector: false,
  assetCategories: null,
};

const projectReducer = (state, action) => {
  switch (action.type) {
    case 'PROJECT_COORDS':
      return { ...state, projectCoords: action.payload };

    case 'PROJECT_ZOOM':
      return { ...state, projectZoom: action.payload };

    case 'PROJECT_NAME':
      return { ...state, projectName: action.payload };

    case 'ROUTE_COORDS':
      return { ...state, routeCoords: action.payload };

    case 'ROUTE_DISTANCE':
      return { ...state, routeDistance: action.payload };

    case 'SELECTED_ASSET_CATEGORIES':
      return { ...state, selectedCategories: action.payload };

    case 'SELECTED_ASSET_TYPES':
      return { ...state, selectedTypes: action.payload };

    case 'ADD_VECTOR':
      return { ...state, addVector: action.payload };

    case 'VECTOR_SOURCE':
      return { ...state, vectorSource: action.payload };

    case 'VECTOR_DESTINATION':
      return { ...state, vectorDestination: action.payload };

    case 'SENSOR_ADDED':
      return { ...state, sensorAdded: action.payload };

    case 'SENSOR_DELETED':
      return { ...state, sensorDeleted: action.payload };

    case 'SENSORS_DETAIL':
      return { ...state, sensorsDetail: action.payload };

    case 'RADAR_ADDED':
      return { ...state, radarAdded: action.payload };

    case 'RADAR_DELETED':
      return { ...state, radarDeleted: action.payload };

    case 'ENVSTATION_DELETED':
      return { ...state, envstationDeleted: action.payload };

    case 'DAI_DELETED':
      return { ...state, daiDeleted: action.payload };

    case 'VMS_DELETED':
      return { ...state, vmsDeleted: action.payload };

    case 'CHARGER_DELETED':
      return { ...state, chargerDeleted: action.payload };

    case 'CAMERA_ADDED':
      return { ...state, cameraAdded: action.payload };

    case 'CAMERA_DELETED':
      return { ...state, cameraDeleted: action.payload };

    case 'VECTOR_ADDED':
      return { ...state, vectorAdded: action.payload };

    case 'VECTOR_DELETED':
      return { ...state, vectorDeleted: action.payload };

    case 'GPS_ADDED':
      return { ...state, gpsAdded: action.payload };

    case 'ENVSTATION_ADDED':
      return { ...state, envstationAdded: action.payload };

    case 'DAI_ADDED':
      return { ...state, daiAdded: action.payload };

    case 'VMS_ADDED':
      return { ...state, vmsAdded: action.payload };

    case 'CHARGER_ADDED':
      return { ...state, chargerAdded: action.payload };

    case 'NEW_DEVICE_LOCATION':
      return { ...state, newDeviceLocation: action.payload };

    case 'SENSORS_BOUNDS':
      return { ...state, sensorsBounds: action.payload };

    case 'RADARS_BOUNDS':
      return { ...state, radarsBounds: action.payload };

    case 'CAMERAS_BOUNDS':
      return { ...state, camerasBounds: action.payload };

    case 'VECTORS_BOUNDS':
      return { ...state, vectorsBounds: action.payload };

    case 'ALARM_BOUNDS':
      return { ...state, alarmBounds: action.payload };

    case 'GPS_BOUNDS':
      return { ...state, gpsBounds: action.payload };

    case 'ENVSTATION_BOUNDS':
      return { ...state, envstationsBounds: action.payload };

    case 'DAI_BOUNDS':
      return { ...state, daiBounds: action.payload };

    case 'VMS_BOUNDS':
      return { ...state, vmsBounds: action.payload };

    case 'CHARGERS_BOUNDS':
      return { ...state, chargersBounds: action.payload };

    case 'MOVEMAP':
      return { ...state, moveMap: action.payload };

    case 'BTSENSORS_LAYER':
      return { ...state, btSensorsLayer: action.payload };

    case 'RADARS_LAYER':
      return { ...state, radarsLayer: action.payload };

    case 'CAMERAS_LAYER':
      return { ...state, camerasLayer: action.payload };

    case 'GPS_LAYER':
      return { ...state, gpsLayer: action.payload };

    case 'ENVSTATIONS_LAYER':
      return { ...state, envstationsLayer: action.payload };

    case 'DAI_LAYER':
      return { ...state, daiLayer: action.payload };

    case 'VMS_LAYER':
      return { ...state, vmsLayer: action.payload };

    case 'CHARGER_LAYER':
      return { ...state, chargerLayer: action.payload };

    case 'NEW_VECTOR':
      return { ...state, newVector: action.payload };

    case 'ASSET_CATEGORIES':
      return { ...state, assetCategories: action.payload };

    default:
      throw new Error();
  }
};

const ProjectManagementProvider = (props) => {
  const [state, dispatch] = useReducer(projectReducer, defaultProjectState);

  return (
    <ProjectManagementContext.Provider value={[state, dispatch]}>
      {props.children}
    </ProjectManagementContext.Provider>
  );
};

export default ProjectManagementProvider;
